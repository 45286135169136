import { Link, navigate } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { ReactNode } from "react";

import BackgroundShape from "../../atoms/background-shape/BackgroundShape";
import Button from "../../atoms/button/Button";
import DogsInRoomSVG from "../../images/templates/error/dogsInRoom.svg";
import Meta from "../../organisms/meta/meta";
import PageWrapper from "../../organisms/page-wrapper/PageWrapper";
import { homePath } from "../../utils/locale-configuration-utils";
import * as styles from "./Error.module.scss";

interface Image {
  default: string;
}

const logoSVG: Image = require("../../images/logo/barkibu.svg") as Image;

const logoSVGSrc: string = logoSVG.default;

interface ErrorPageProps {
  metaTitle: string;
  title: string;
  subtitle: ReactNode;
}

const ErrorPage = ({ metaTitle, title, subtitle }: ErrorPageProps): JSX.Element => {
  const { t } = useTranslation();
  const { i18n } = useI18next();
  const SubtitleTag = typeof subtitle === "string" ? "p" : "div";

  return (
    <PageWrapper>
      <Meta
        title={metaTitle}
        lang={i18n.language}
        bodyClass={"tp-error"}
        slug={undefined}
        description={undefined}
        disableTranslations={undefined}
      />
      <header id="header" className={styles.navigation}>
        <Link to={"/"} className="brand">
          <img src={logoSVGSrc} alt="Barkibu" width="90px" height="21px" />
        </Link>
      </header>
      <main id="main" className={styles.main}>
        <header className={styles.hero}>
          <h1 className={styles.title}>{title}</h1>
          {subtitle && <SubtitleTag className={styles.subtitle}>{subtitle}</SubtitleTag>}

          <Button className={styles.button} isLoading={false} onClick={() => navigate(homePath())}>
            {t("pg_error.cta")}
          </Button>
          <DogsInRoomSVG className={styles.illustration} />
        </header>
      </main>
      <BackgroundShape adoptionClassName="backgroundShapeError" />
    </PageWrapper>
  );
};

export default ErrorPage;
