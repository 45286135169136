import { graphql } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { useEffect } from "react";

import ErrorPage from "../templates/error/Error";
import { getBestLocale } from "../utils/browser-features";

const Page = (): JSX.Element => {
  const { i18n } = useI18next();
  const { t } = useTranslation();

  useEffect(() => {
    if (!i18n) {
      return;
    }

    void i18n.changeLanguage(getBestLocale());
  }, [i18n]);

  return (
    <ErrorPage
      metaTitle={t("pg_error.not_found.meta.title")}
      title={t("pg_error.not_found.title")}
      subtitle={t("pg_error.not_found.subtitle")}
    />
  );
};

export default Page;

export const query = graphql`
  query Page404Query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
