import cn from "classnames";
import { motion, useReducedMotionConfig } from "framer-motion";

import { useAnimatedPaths } from "../../utils/hooks/useAnimatedPaths";
import * as styles from "./BackgroundShape.module.scss";

interface BackgroundShapeProps {
  adoptionClassName?: string;
  variantName?: string;
  hasAnimatedBackground?: boolean;
  animationDuration?: number;
}

const path1 =
  "M6 461C-1 361-20 102 93 45c112-56 471-63 665 0 195 63 348-55 358 118 10 172 215 80 141 309-74 230-200 300-423 203s-424 32-567 12S14 586 6 461Z";
const path2 =
  "m 81.200472,443.60342 c -7,-100 -134.0485,-175.20486 -8.4758,-367.779208 88.719098,-136.05678 276.271518,18.51781 561.920668,-43.01132 199.52681,-42.97828 499.33476,-38.4554895 509.33476,134.544508 10,172 161.9154,156.61909 104.8968,355.04581 C 1066.3125,807.98022 756.00045,702.60006 558.98701,649.32632 324.23505,585.84783 358.05873,701.29734 213.70085,704.43036 -64.111647,710.45978 89.200472,568.60342 81.200472,443.60342 Z";
const path3 =
  "m 1197.9844,443.60342 c 7,-100 134.0485,-175.20486 8.4758,-367.77921 C 1117.7411,-60.232566 930.18868,94.342022 644.53953,32.812896 445.01272,-10.165389 145.20478,-5.6425974 135.20478,167.3574 c -10,172 -161.915394,156.61909 -104.896768,355.04581 73.999998,230 247.625408,189.43156 445.420638,152.59679 239.07282,-44.52177 422.64217,8.86698 567.00005,12 277.8125,6.02942 147.2557,-118.39658 155.2557,-243.39658 z";
const paths = [path1, path2, path3, path1];

const BackgroundShape = ({
  variantName,
  adoptionClassName,
  hasAnimatedBackground = false,
  animationDuration = 2,
}: BackgroundShapeProps): JSX.Element => {
  const path = useAnimatedPaths(paths, animationDuration);
  const reduceMotion = useReducedMotionConfig();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="var(--background-shape-fill, var(--b-200-color))"
      viewBox="0 0 1274 718"
      className={cn("at-background-shape", styles.backgroundShape, adoptionClassName, {
        [styles[variantName as string]]: variantName,
      })}
      aria-hidden="true"
      focusable="false"
      tabIndex={-1}
    >
      {hasAnimatedBackground && !reduceMotion ? (
        <motion.path initial={false} d={path} fill={"var(--p-200-color)"} />
      ) : (
        <path d={paths[0]} />
      )}
    </svg>
  );
};

export default BackgroundShape;
